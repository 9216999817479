<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="createType == 'edit'">
              Edit Company Information
              <v-progress-circular indeterminate class="ml-3" color="primary">
              </v-progress-circular>
            </span>
            <span v-if="createType == 'add'">Add Company</span>
          </h3>

          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text">
              There was an error.
            </v-card-subtitle>
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="createType == 'edit'">Edit Company Information</span>
            <span v-if="createType == 'add'">Add Company</span>
          </h3>

          <p class="py-4">
            Please fill out the form below to add a company. After creating a
            company, you will need to associate accounts to the company so they
            can log in.
          </p>

          <v-form @submit="submitCreateCompany" ref="form" v-model="form.valid">
            <v-text-field
              v-model="companyData.name"
              label="Company Name"
              :rules="form.rules.required"
              outlined
            />

            <v-textarea
              v-model="companyData.description"
              label="Description"
              outlined
            />

            <v-row>
              <v-col cols="12" class="pb-0 mb-n2">Company Type</v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lender"
                  label="Lender"
                  hide-details
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.appraiser"
                  label="Appraiser"
                  hide-details
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lawyer"
                  label="Lawyer"
                  hide-details
                />
              </v-col>
            </v-row>

            <br />
            <br />

            <v-select
              v-if="companyData.lender"
              :items="companyClassOptions"
              v-model="companyData.lending_profile.lender_type"
              label="Lender Class"
              :rules="form.rules.required"
              outlined
            />

            <v-select
              :items="companyGroupOptions"
              v-model="companyData.lending_profile.tier"
              label="Group Name"
              outlined
            />

            <v-select
              :items="kbn_stgs"
              v-model="companyData.kbn_stg.id"
              label="Lender Stage"
              outlined
            />

            <v-text-field
              label="Company Phone Number"
              placeholder="Company Phone Number"
              outlined
              v-model="companyData.phone"
              :rules="form.rules.phoneRules"
            />

            <v-text-field
              label="Company Website"
              placeholder="Company Website"
              outlined
              v-model="companyData.website"
            />

            <v-row>
              <v-col cols="12" class="pb-0 mb-n2">Service Areas</v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lending_profile.BC"
                  label="BC"
                  hide-details
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lending_profile.AB"
                  label="Alberta"
                  hide-details
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lending_profile.ON"
                  label="Ontario"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="companyData.active"
                  label="Currently An Active Company"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text">
                    There was an error.
                  </v-card-subtitle>
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>

                <v-btn
                  v-if="createType == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateCompany"
                >
                  Create Company
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  />
                </v-btn>

                <v-btn
                  v-if="createType == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateCompany"
                >
                  Update Company
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  />
                </v-btn>

                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'edit'"
                  @click="$router.go(-1)"
                >
                  Cancel
                </v-btn>

                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'add'"
                  @click="
                    $router.push({ path: `/dashboard/admin/company/list` })
                  "
                >
                  Go To Company List
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';

export default {
  name: 'company_add',
  props: ['company_id'],
  data() {
    return {
      createType: '',
      formLoading: false,
      componentLoading: true,
      error: '',
      submitError: '',
      form: {
        valid: false,
        rules: {
          required: [(v) => !!v || 'Name is required'],
          companyPhoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              'Phone must be numbers only (ex. 7803334444)',

            (v) =>
              (v && v.length == 10) ||
              'Phone number must be 10 digits no spaces or characters (format 7803334444)',
          ],
          phoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              'Phone must be numbers only (ex. 7803334444)',
            (v) => !!v || 'Phone number is required',
            (v) =>
              (v && v.length == 10) ||
              'Phone number must be 10 digits no spaces or characters (format 7803334444)',
          ],
          emailRules: [
            (v) => (v && !!v) || 'E-mail is required',
            (v) => (v && /.+@.+/.test(v)) || 'E-mail must be valid',
          ],
        },
      },

      companyData: {
        name: '',
        description: '',
        verified: '',
        active: '',
        lender: false,
        appraiser: false,
        lawyer: false,
        lending_profile: {
          tier: '',
          AB: false,
          BC: false,
          ON: false,
        },

        kbn_stg: {
          id: 1,
        },

        phone: '',

        website: '',
      },

      companyGroupOptions: [
        { value: 'none', text: 'None' },
        { value: 'tier 1', text: 'Round 1' },
        { value: 'tier 2', text: 'Round 2' },
        { value: 'tier 3', text: 'Round 3' },
        { value: 'tier 4', text: 'Round 4' },
        { value: 'hail mary', text: 'Screw it.... Hail Mary!' },
      ],

      companyTypeOptions: [
        { value: 'lender', text: 'Lender' },
        { value: 'appraiser', text: 'Appraiser' },
        { value: 'legal', text: 'Legal' },
        { value: 'other', text: 'Other' },
      ],

      companyClassOptions: [
        { value: 'MIC', text: 'Mortgage Investment Company' },
        { value: 'Private', text: 'Private Company' },
        { value: 'Institutional', text: 'Institutional' },

        { value: 'Broker', text: 'Broker' },
        { value: 'Other', text: 'Other' },
      ],

      kbn_stgs: [{ value: 0, text: 'Choose Stage'}],
    };
  },

  methods: {
    submitCreateCompany() {
      this.formLoading = true;
      this.submitError = '';

      this.$refs.form.validate();

      if (this.form.valid) {
        API()
          .post('/api/internal-admin/company', this.companyData)
          .then((res) => {
            console.log(res.data);
            if (res.status == 201) {
              this.$router.push(`/dashboard/admin/company/read/${res.data.id}`);
            } else {
              this.formLoading = false;
              this.submitError = true;
            }
          })
          .catch((e) => {
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    submitUpdateCompany() {
      this.formLoading = true;
      this.submitError = '';

      this.$refs.form.validate();

      if (this.form.valid) {
        API()
          .patch(`/api/internal-admin/company/`, this.companyData)
          .then((res) => {
            this.formLoading = false;

            this.$router.go(-1);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    async get_company_info() {
      try {
        let company_info = await API().get(
          `/api/internal-admin/company/${this.company_id}`
        );

        this.companyData = company_info.data;
        console.log(this.companyData);

        if(this.companyData.kbn_stg === null) {
          this.companyData.kbn_stg = { id: 0 };
        }

        this.componentLoading = false;
      } catch (error) {
        alert(error);
        alert('error loading company information');
      }
    },

    async getKanbanStages() {
      try {
        const res = await API().get('api/kanban/get_kanban_lender_data');

        res.data.forEach((el) => {
          this.kbn_stgs.push({
            value: el.id,
            text: el.stage_name,
          });
        });
      } catch (error) {
        console.log(error);
        alert('error loading kanban stages');
      }
    },
  },

  async mounted() {
    if (this.$route.name == 'company_add') {
      this.createType = 'add';
      this.getKanbanStages();

      this.componentLoading = false;
    } else if (this.$route.name == 'company_edit') {
      await this.get_company_info();
      this.getKanbanStages();
      this.createType = 'edit';
      this.componentLoading = false;
    }
  },
};
</script>
